//racelist
@font-face {
  src: local();
  font-family: source-sans-pro, sans-serif;

  font-weight: 400;

  font-style: normal;
}
.class-filter {
  margin: 0 5px;
  height: 50px;
  width: 96px;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  font-family: Arial;
  font-weight: 600;
  background-image: url("../../assets/class-filter-bg.png");
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    -webkit-filter: brightness(80%);
    transition: all 300ms ease;
  }

  @media (max-width: 1256px) {
    width: 93px;
  }
}
//?????

.racelist {
  overflow: auto;
  flex-grow: 1;
  background-color: black;
  --grid-layout: 4fr 3fr 3fr 3fr 3fr 3fr 3fr;

  .topbar {
    // height: 150px;
    // padding-top: 24px;
    // padding-bottom: 24px;
    background: black;
    color: var(--main-green);
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
    }

    .sides {
      font-family: Arial;
      font-weight: 600;
      margin: 0 25px;
      padding-top: 15px;
      min-width: 50px;
      white-space: nowrap;
      text-decoration: none;
    }

    .next {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 10px;
      padding: 18px 0px;
      align-items: center;

      @media (min-width: 1480px) {
        display: grid;
        padding: 18px 0px;
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }

      .box {
        // margin: 0 25px;
        padding: 15px 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        // width: 225px;
        height: 117px;
        background: linear-gradient(to bottom, #ffffff -200%, #000000 100%);
        border: 1px solid var(--main-green);
        border-radius: 15px;
        color: rgb(255, 255, 255);
        font-family: Audiowide;
        font-weight: 400;
        font-size: 20px;
        transition: all ease 300ms;

        @media (min-width: 1676px) {
          height: 117px;
        }

        cursor: pointer;

        &:hover {
          background: rgb(66, 66, 66);
          color: white;
        }

        .race-name {
          font-family: Audiowide;
          font-weight: 400;
          font-size: 24px;

          @media (min-width: 1200px) and (max-width: 1480px) {
            font-size: 18px;
          }
        }

        .time {
          display: flex;
          width: 100%;

          align-items: center;
          letter-spacing: 0.5px;
          font-family: Arial;
          font-weight: 600;

          .start {
            color: yellow;
            font-style: normal;
          }

          .clock {
            margin-left: 10px;
            white-space: nowrap;
            color: var(--main-green);
            font-style: italic;
            font-size: 1vw;
          }
        }
      }
    }

    .loading-data {
      height: 117px;
    }
  }

  .race-classes {
    display: flex;
    // align-items: center;
    padding: 0 25px;
    height: 100px;
    background: rgb(54, 54, 54);
    position: relative;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    .race-title-main {
      width: 100%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
    @media (max-width: 1652px) {
      height: auto;
    }
    @media (max-width: 768px) {
      height: auto;
      flex-wrap: wrap;
    }

    // @media (max-width:768px) {
    //   display: block;
    // }
    .title {
      font-family: Audiowide;
      font-size: 20px;
      color: white;
      display: flex;
      // min-width: 265px;

      align-items: center;

      img {
        margin-left: 24px;
      }

      .down-arrow {
        width: 25px;
        height: 25px;
      }

      @media (max-width: 1256px) {
        img {
          margin-left: 15px !important;
        }
      }
    }

    .filter-box {
      display: flex;
      // position: absolute;
      // left: 25px;
      // bottom: 0;
      align-items: center;
      // margin-bottom: 5px;
      .filter-label {
        // margin-top: 8px;
        .filter-btn {
          padding: 5px 10px;
          color: #ffffff;
          display: inline-block;
          font-size: 14px;
          font-weight: 800;
          border-radius: 2px;
        }

        .cancel-btn {
          background-color: rgb(227, 37, 69);
          margin-left: 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }

    .classes {
      margin-left: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }

      @media (max-width: 1256px) {
        margin-left: 0px;
      }
    }
  }

  .race-tournament-classes {
    flex-direction: row !important;
    justify-content: flex-start;
  }

  .race-results-classes {
    flex-direction: row !important;
    justify-content: flex-start;
  }

  .race-history-classes {
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
  }

  .list {
    position: relative;
    // height: 100%;
    //TODO: scroll
    overflow-x: auto;

    .race-event {
      .info {
        margin-left: 6px;
      }
    }

    .summary-columns {
      grid-template-columns: 4fr 3fr 3fr 3fr 3fr !important;
    }

    .titles {
      :nth-child(1) {
        display: block;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;

        @media (max-width: 1024px) {
          justify-content: center;
        }
      }

      .info-tooltip {
        display: inline !important;
      }
      .min-w-120 {
        min-width: 200px;
        max-width: 200px;
      }

      min-width: 700px;
      padding: 10px 25px;
      background: black;
      font-family: Arial;
      font-size: 16px;
      font-weight: 600;
      color: var(--main-green);
      text-transform: uppercase;
      display: grid;
      grid-template-columns: var(--grid-layout);
      position: sticky;
      top: 0;
      z-index: 10;
    }

    .tournament-titles {
      :nth-child(1) {
        display: block;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;

        @media (max-width: 1024px) {
          justify-content: center;
        }
      }

      .min-w-120 {
        min-width: 200px;
        max-width: 200px;
      }

      min-width: 936px;
      padding: 10px 25px;
      background: black;
      font-family: Arial;
      font-size: 16px;
      font-weight: 600;
      color: var(--main-green);
      text-transform: uppercase;
      display: grid;
      grid-template-columns: var(--grid-layout);
    }

    .race-row {
      padding: 10px 25px;
      display: grid;
      grid-template-columns: var(--grid-layout);
      background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
      height: 80px;
      text-decoration: none;

      div {
        min-width: 120px;
      }

      .race-titles {
        font-family: Audiowide;
        font-size: 20px;
        color: white;
        font-weight: 600;
        min-width: 200px;
        max-width: 200px;
        display: flex;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;

        span:last-child {
          font-family: Arial;
          color: var(--main-green);
        }
        @media (max-width: 1250px) {
          font-size: 15px !important;
        }
      }

      .grid-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Arial;
        font-weight: 600;
        text-decoration: none;
        letter-spacing: 1px;
      }

      .event-type-box {
        flex-direction: column;
        gap: 10px;
      }

      .top-label-cover {
        min-width: 120px;
        margin-top: 5px;
        .top-label {
          display: inline;
          padding: 8px;
          border-radius: 2px;
          font-size: 15px;
          color: #fff;
        }
      }

      .distance {
        color: #00a128;
      }

      .entry-fee {
        color: rgb(255, 251, 0);
      }

      .prize-pool {
        color: #00a128;
      }

      .registered-racers {
        color: white;
      }
    }

    .race-event-races {
      min-width: 876px !important;

      .race-event {
        justify-content: center !important;
      }

      .race-titles {
        min-width: 200px !important;
        max-width: 200px !important;
        @media (max-width: 1000px) {
          min-width: 120px !important;
          max-width: 120px !important;
        }
      }
    }

    .race-result-titles {
      div {
        &:nth-child(2) {
          justify-content: center;
          min-width: 100px !important;
        }

        min-width: auto !important;
      }
    }

    .race-result-row {
      min-width: 751px !important;
    }

    .races {
      min-width: 986px;
      height: calc(100% - 20px);
      background: black;
      color: white;
      // margin-top: 20px;
      @media (min-width: 1000px) {
        max-height: calc(100vh - 23rem);
        min-height: calc(100vh - 23rem);
        // overflow: auto;
      }
      .none {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .race-row {
        padding: 10px 25px;
        display: grid;
        grid-template-columns: var(--grid-layout);
        background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
        height: 80px;
        text-decoration: none;

        .race-titles {
          font-family: Audiowide;
          font-size: 20px;
          color: white;
          min-width: 200px;
          max-width: 200px;
          font-weight: 600;
          display: flex;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          cursor: pointer;

          span:last-child {
            font-family: Arial;
            color: var(--main-green);
          }

          @media (max-width: 768px) {
            min-width: 120px;
          }
        }

        .grid-item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Arial;
          font-weight: 600;
          text-decoration: none;
          letter-spacing: 1px;
        }

        .distance {
          color: #00a128;
        }

        .entry-fee {
          color: rgb(255, 251, 0);
        }
        .entry-fee-clor {
          color: white;
        }
        .prize-pool {
          color: #00a128;
        }

        .registered-racers {
          color: white;
        }
        .status_wait {
          color: #fff;
          text-transform: uppercase;
        }
        .event-green {
          color: #00a128;
        }
        .event-white {
          color: #ffffff !important;
        }
      }
    }
    .races-seco {
      min-width: auto;
      height: calc(100% - 20px);
      background: black;
      color: white;
      margin-top: 20px;
      grid-column: span 2 / span 2;
    }
  }
}

.race-enter {
  .MuiMenu-paper {
    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
    margin-left: 3px;

    .MuiMenuItem-root {
      color: #fff;
      font-size: 18px;
    }
  }
}

.gates {
  .MuiMenu-paper {
    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
    margin-left: 3px;

    @media (max-width: 425px) {
      left: 225px !important;
    }
    @media (max-width: 375px) {
      left: 200px !important;
    }
    @media (max-width: 320px) {
      left: 152px !important;
    }

    .MuiMenuItem-root {
      color: #fff;
      font-size: 18px;
    }
  }
}

.payouts {
  .MuiMenu-paper {
    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
    margin-left: 3px;
    padding: 5px 8px;

    @media (max-width: 425px) {
      left: 176px !important;
    }
    @media (max-width: 375px) {
      left: 152px !important;
    }
    @media (max-width: 320px) {
      left: 103px !important;
    }

    .MuiMenuItem-root {
      color: #fff;
      font-size: 18px;
      border-radius: 5px;
      text-align: center;
      justify-content: center;
    }

    .MuiMenuItem-root:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.entry-fee {
  .MuiMenu-paper {
    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
    margin-left: 3px;

    @media (max-width: 425px) {
      left: 172px !important;
    }
    @media (max-width: 375px) {
      left: 148px !important;
    }
    @media (max-width: 320px) {
      left: 99px !important;
    }

    .MuiMenuItem-root {
      color: #fff;
      font-size: 18px;
    }
  }
}
.event-white-last :last-child {
  color: #fff !important;
}

.status-clr {
  color: #fff;
  text-transform: uppercase;
}
@media (min-width: 1000px) and (max-width: 1024px) {
  .race-main-list {
    .races {
      .race-row.tour-race-row {
        gap: 9px !important;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .race-main-list {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    .tournament-titles {
      min-width: auto !important;
      display: flex !important;
      gap: 10px;
      flex-direction: column;
      div {
        justify-content: start !important;
      }
    }
    .races {
      min-width: auto !important;
      height: auto !important;
      margin-top: 0 !important;
      .race-row.tour-race-row {
        display: flex;
        height: auto;
        flex-direction: column;
        gap: 12px;
        justify-content: left !important;
        align-items: start !important;
        background: #000000;
        .race-titles {
          flex-direction: row;
          min-width: auto;
          max-width: none;
          justify-content: start;
          align-items: center;
          gap: 5px;
        }
      }
      .race-row {
        .grid-item {
          justify-content: left !important;
          align-items: start !important;
        }
      }
    }
  }
}
.clipboard {
  margin-left: 9px;
  width: 28px;
  height: 36px;
  cursor: pointer;
  transition: all 0.3s;

  @media (max-width: 768px) {
    width: 16px !important;
  }

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.75;
  }
}

.race-detail {
  flex-grow: 1;
  background: black;
  overflow: auto;

  // .race-game-event-main {
  //   overflow: auto;
  // }

  // .race-game-event {
  //   min-width: 1400px;
  // }

  .grid-5 {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr 3fr;
    max-width: 1366px;
    align-items: center;

    div {
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    .race-track-detail {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .titles {
        div {
          height: 34px;
          font-size: 12px;
          line-height: 27px;
        }
      }
    }
  }

  .titles {
    div:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    @media (max-width: 768px) {
      &.grid-5 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        padding: 10px 14px;
      }
    }

    padding: 10px 25px;
    background: black;
    font-family: Arial;
    font-size: 16px;
    font-weight: 600;
    color: var(--main-green);
    text-transform: uppercase;
  }

  .info-bar {
    height: 100px;
    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 769px) and (max-width: 1300px) {
      display: block;

      .sponsor {
        img {
          width: 126px !important;
        }
      }
    }

    @media (max-width: 1300px) {
      .track-conditions {
        justify-content: left !important;
      }
    }

    @media (max-width: 768px) {
      height: auto;
      display: block;
      padding: 10px 12px;
      background: #000000;

      .grid-5 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      // .sponsor{
      //   grid-column: span 2 / span 2;
      // }
    }

    .trackname {
      font-family: Audiowide;
      font-size: 24px;
      color: white;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @media (max-width: 768px) {
        font-size: 11px;
        flex-direction: row;
        align-items: center;
        height: 34px;
        justify-content: left;
      }
    }

    .planet {
      font-family: Arial;
      font-size: 20px;
      font-weight: 600;
      color: var(--main-green);
      font-style: italic;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        font-size: 12px;
      }

      span {
        position: relative;

        div {
          font-size: 12px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
          border-radius: 2px;
          z-index: 10;
          top: 0;
        }
      }
    }

    .track-conditions {
      font-family: Arial;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: grey;
      font-style: italic;
      display: flex;
      align-items: center;
      // justify-content: center;

      @media (max-width: 768px) {
        font-size: 12px;
        height: 34px;
        justify-content: left;
      }
    }

    .sponsor {
      display: flex;
      padding-right: 5px;
      max-width: 500px;
      box-sizing: border-box;

      .msg {
        max-width: 250px;
        overflow-wrap: break-word;
        font-family: Arial;
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-style: italic;
        display: flex;
        align-items: center;
        margin-right: 5px;

        @media (max-width: 768px) {
          font-size: 11px;
        }
      }

      img {
        width: 150px;
        object-fit: contain;

        @media (max-width: 768px) {
          width: 60px;
        }
      }
    }

    .wrapper {
      display: flex;
      margin-right: auto;
      margin-left: 10px;

      .watch-3d {
        align-items: center;
        border: 2px solid var(--main-green);
        border-radius: 2px;
        color: #fffb00;
        cursor: pointer;
        display: flex;
        font-family: Arial;
        font-size: 20px;
        font-style: italic;
        font-weight: 600;
        justify-content: center;
        max-width: 100px;
        height: 40px;
        padding: 8px 20px;

        img {
          margin-left: 30px;
        }
        .custom-tooltip {
          top: 100%;
          &::after {
            content: none !important;
          }
        }
      }

      .live {
        justify-self: flex-end;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 25px;
        font-family: Arial;
        font-size: 20px;
        font-weight: 600;
        color: white;
        font-style: italic;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .extra-info-bar {
      padding: 10px 0px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      height: auto !important;
      display: grid !important;
      gap: 10px !important;

      .info {
        justify-content: left !important;
        height: 28px !important;
      }
    }

    .info-btn {
      margin: 0px 12px !important;
    }
  }

  .extra-info-bar {
    height: 60px;
    background: rgb(54, 54, 54);
    display: flex;
    align-items: center;

    .event-type {
      margin-left: 10px;
    }

    .ReplyBtn {
      color: white;
      background-color: #1976d2;
      margin-right: 10px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 12px;
    }

    .info {
      margin: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--main-green);
      font-family: Arial;
      font-size: 16px;
      font-weight: 600;

      @media (max-width: 768px) {
        font-size: 12px;
      }

      .top-label-cover {
        .top-label {
          padding: 8px;
          border-radius: 2px;
          font-size: 16px;
          color: #fff;
          text-align: center;
        }
      }

      span {
        margin-left: 10px;
        color: white;
      }
    }
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    .extra-info-bar {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      height: auto;
      padding-top: 10px;
      gap: 10px;
      padding-bottom: 10px;
      justify-content: space-evenly !important;
    }

    .trackname {
      font-size: 16px !important;
    }

    .info {
      margin: 0 10px !important;
      justify-content: left;
      font-size: 13px !important;

      span {
        margin-left: 5px !important;
        color: white;
      }
    }

    .event-type {
      margin-left: 10px !important;
    }
  }

  @media (min-width: 1441px) and (max-width: 1500px) {
    .info {
      margin: 0 13px !important;
    }
  }

  @media (min-width: 1500px) {
    .info-btn {
      margin: 0px !important;
    }
  }

  .blackbar {
    background: black;
    height: 40px;

    @media (max-width: 768px) {
      display: none !important;
    }
  }

  .race-area {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.651) -150%,
      rgba(0, 0, 0, 1) 100%
    );
    padding: 20px 40px;

    @media (max-width: 575px) {
      padding: 20px 12px;
    }

    .sections {
      display: flex;

      @media (max-width: 661px) {
        display: flex;
        flex-direction: column;
      }

      .positions {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .hidden {
          visibility: hidden;
        }

        .icon {
          font-weight: 600;
          font-family: Arial;
          font-size: 18px;
          width: 55px;
          height: 55px;
          border-radius: 27.5px;
          background: black;
          border: 2px solid white;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          color: white;

          @media (max-width: 660px) {
            width: 39px;
            height: 39px;
          }
        }

        .first {
          color: black;
          border: 2px solid black;
          background: gold;
        }

        .second {
          color: black;
          border: 2px solid gold;
          background: silver;
        }

        .third {
          color: black;
          border: 2px solid gold;
          background: rgb(255, 145, 0);
        }
      }

      .racers {
        position: relative;
        width: 450px;

        @media (max-width: 1300px) {
          width: 200px;
          margin-left: 7px !important;
        }

        .row {
          display: flex;
          // margin: 10px 0;
          position: absolute;
          transition: ease all 0.3s;

          .dog {
            transition: ease 300ms all;
            width: 400px;
            height: 55px;
            position: relative;
            background: black;
            border: 2px solid var(--main-green);
            border-radius: 3px;
            margin-left: 20px;
            overflow: hidden;

            @media (max-width: 660px) {
              height: 38px;
            }

            @media (min-width: 300px) and (max-width: 575px) {
              width: 225px;

              .name-type {
                width: 42% !important;
                font-size: 12px;
              }
            }

            @media (min-width: 575px) and (max-width: 1300px) {
              width: 181px;
              margin-left: 10px;
            }

            // @media (min-width:600px) and (max-width:1300px) {
            //   width: 181px;
            //   margin-left: 10px;
            // }

            .img-container {
              overflow: hidden;
              max-width: 90px;

              img {
                vertical-align: bottom;
                height: 55px;
                object-fit: cover;
                transform-origin: 15% 5%;
                scale: 200%;
              }

              @media (max-width: 1300px) {
                max-width: 60px;
              }

              @media (max-width: 660px) {
                max-width: 59px;
              }
            }

            .divider {
              position: absolute;
              left: 0px;
              background: none;
              height: 55px;
              width: 56px;
            }

            .name-type {
              width: 55%;
              position: absolute;
              top: 5px;
              left: 94px;
              font-family: Arial;

              .name {
                font-weight: 600;
                padding-left: 15px;
                padding-bottom: 5px;
                color: white;
                border-bottom: 1px solid var(--main-green);
              }

              .type {
                font-weight: 600;
                padding-left: 15px;
                padding-top: 5px;
                color: grey;
              }

              @media (max-width: 1300px) {
                font-size: 10px;
                top: 12px;
                left: 58px;
              }

              @media (max-width: 660px) {
                top: 3px;
              }
            }

            .number {
              color: white;
              position: absolute;
              top: 14%;
              left: 80%;
              font-weight: 600;
              font-size: 32px;
              font-style: italic;
              // color: var(--main-green);

              @media (max-width: 575px) {
                top: 27%;

                font-size: 20px;
              }

              @media (max-width: 1300px) {
                top: 27%;
                font-size: 18px;
                left: 85% !important;
              }

              @media (max-width: 660px) {
                top: 24%;
              }
            }
          }
        }
      }
    }
  }
}

.event-type {
  //background-image: url("../../assets/class-bg.png");

  //background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--main-green);
  border-radius: 2px;
  width: 72px;
  height: 34px;
  color: white;
  font-family: Arial;
  font-weight: 600;
}

.countdown {
  color: var(--main-green);
  font-family: Audiowide;
  font-size: 90px;
  position: absolute;
  top: calc(50% - 45px);
  left: 50%;
  z-index: 10;
  opacity: 1;
  transition: visibility 1s, opacity 1s linear;

  @media (max-width: 375px) {
    left: 70%;
  }
}

.grad-bottom {
  background: linear-gradient(to bottom, #ffffff71 -300%, #000000 100%);
  flex: 1 1 auto;
  width: 100%;
}

.race-summary {
  color: white;
  display: flex;

  @media (max-width: 1600px) {
    flex-direction: column;
  }

  .onefour {
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }

  .fiveeight {
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }

  .position-row {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;

    @media (max-width: 768px) {
      height: 110px;
    }

    @media (max-width: 575px) {
      height: 80px;
    }

    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);

    .position-index {
      color: yellow;
      // font-family: Arial;
      font-size: 36px;
      padding: 20px 40px;
      font-variant-numeric: ordinal;
      width: 115px;
      box-sizing: border-box;
      font-family: source-sans-pro;

      @media (max-width: 768px) {
        width: 95px;
        font-size: 24px;
      }

      @media (max-width: 575px) {
        width: 38px;
        padding: 20px 7px;
      }
    }

    .position-info {
      width: 100%;
      height: 100%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      font-family: Audiowide;

      .img-container {
        height: 100%;
        max-width: 200px;
        overflow: hidden;

        @media (max-width: 575px) {
          max-width: 59px;
        }

        img {
          max-height: 100px;
          scale: 200%;
          transform-origin: 20% 5%;

          @media (max-width: 768px) {
            max-height: 62px;
            scale: 248%;
            transform-origin: 15% 0%;
          }
        }
      }

      .detail {
        width: 100%;
        height: 100%;

        justify-content: center;
        flex-direction: column;

        .top {
          display: grid;
          grid-template-columns: 1fr 5fr 4.2fr;
          // display: flex;
          height: 50%;
          box-sizing: border-box;

          border-bottom: 1px solid grey;
          padding-bottom: 10px;
          position: relative;

          img {
            width: 40px;
            height: 40px;
            position: relative;
            top: -2px;
            left: -2px;
          }

          .top-1 {
            .name {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              font-size: 20px;
              text-align: center;

              @media (min-width: 576px) and (max-width: 768px) {
                font-size: 14px;
              }
              @media (max-width: 575px) {
                font-size: 11px;
                align-items: center;
              }
            }

            display: flex;
            justify-content: center;
          }

          .top-2 {
            display: flex;
            margin-left: 30px;

            .extra {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              font-size: 22px;

              @media (min-width: 576px) and (max-width: 768px) {
                font-size: 14px;
              }
              @media (max-width: 575px) {
                font-size: 11px;
                align-items: center;
              }
            }

            .time {
              display: flex;
              font-size: 20px;
              font-family: Arial;
              align-items: flex-end;
              color: yellow;
              margin-left: 10px;

              @media (max-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }
      }

      .bottom {
        display: grid;
        grid-template-columns: 1fr 5fr 4.2fr;
        height: 50%;
        box-sizing: border-box;

        padding-top: 10px;
        border-top: 1px solid grey;
        font-family: Arial;
        font-weight: 600;

        .bot-1 {
          display: flex;
          justify-content: center;

          .bloodline {
            font-size: 20px;
            color: grey;
            text-transform: uppercase;

            @media (min-width: 576px) and (max-width: 768px) {
              font-size: 14px;
            }
            @media (max-width: 575px) {
              font-size: 11px;
            }
          }
        }

        .bot-2 {
          display: flex;
          margin-left: 30px;

          .bloodline {
            font-size: 20px;
            color: grey;
            text-transform: uppercase;

            @media (min-width: 576px) and (max-width: 768px) {
              font-size: 14px;
            }
            @media (max-width: 575px) {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
.pricePool {
  margin-top: 15px;
  color: white;
  // background-color: white;
  display: grid;
  align-items: center;
  justify-content: center;

  .pricePool-title {
    display: flex;
    gap: 8px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    .tittle-amount {
      color: yellow;
    }
  }
  .pool-1 {
    margin-top: 5px;
    display: flex;
    gap: 10px;
  }
  .pricePool-rank {
    color: yellow;
  }
  .pricePool-amount {
    font-weight: 500;
    color: #ffffff;
  }
}
.classes {
  @media (max-width: 768px) {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 1300px) {
  .racelist {
    height: 100%;
  }

  .racelist > .race-classes > .title {
    font-size: 16px !important;
  }

  .search-results {
    margin: 0px;

    input {
      font-size: 0.75em;
      padding: 0px 0px;
      height: 22px;
      width: 150px;
      padding: 8px 18px;
    }

    img {
      width: 1.5em;
      margin: 0px 5px;
    }
  }

  .racelist > .topbar > .next {
    justify-content: space-evenly;
    width: 100%;

    .box {
      margin: none;
      width: fit-content;
      height: fit-content;
    }

    .box > .race-name {
      font-size: 0.75em;
    }

    .box > .time {
      .start {
        font-size: 0.75em;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .racelist > .race-classes > .title {
    width: 40%;
  }

  .racelist > .race-classes > .race-hostory {
    width: 70%;
  }
}

@media (max-width: 576px) {
  .race-hostory {
    display: block !important;
  }

  .search-icon {
    margin-left: 0 !important;
    margin-right: 12px !important;
  }
}

.history {
  // min-width: 700px;
  // flex: 1;
  display: flex;
  align-items: center;
}

.race-history {
  display: block !important;
  @media (min-width: 1000px) {
    height: calc(100vh - 22rem);
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .racelist .race-classes .title {
    gap: 10px;
    margin-left: 10px;
    font-size: 12px !important;
  }

  .show-switch {
    font-size: 12px !important;
  }
}

@media (max-width: 1344px) {
  .show-switch {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1000px) {
  .racing-content-container {
    display: block;
    overflow: hidden;

    .menu {
      width: 100vw;
    }
  }

  .content-padding {
    min-height: 100% !important;
  }

  .racelist {
    .topbar {
      justify-content: space-around;
      display: block;
      height: fit-content;
      padding: 10px 0px;

      .sides {
        width: 100vw;
        margin: auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75em;
      }

      .next {
        text-align: center;
        width: 100%;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .none {
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  .racelist > .race-classes {
    justify-content: space-evenly;
    padding: 10px 14px;
    .race-title-main {
      justify-content: space-evenly;
    }
    // .title {
    //   font-size: 1vh;
    // }

    .title > img {
      width: 1.5em;
      margin: 0px 5px;
    }

    .classes {
      margin: 0px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .search-results {
      margin: 0px;

      input {
        font-size: 0.75em;
        padding: 0px 0px;
        height: fit-content;
        padding: 5px;
        margin: 0px;
      }

      img {
        width: 1.5em;
      }
    }

    .outcome-switch {
      display: flex;
      margin: 0px 5px;
      align-items: center;

      input {
        height: 20px;
      }
    }
  }

  .racelist > .list {
    .titles {
      font-size: 14px;
      // display: flex;

      justify-content: space-between;
      // gap: 20px;
    }
  }

  .racelist > .topbar > .next {
    flex-direction: row;

    .box {
      margin: auto;
    }

    .box > .race-name {
      font-size: 0.5em;
    }
  }

  .racelist > .topbar > .next .box > .time .start {
    font-size: 0.5em;
  }

  .racelist > .topbar > .next .box > .time .clock {
    font-size: 0.5em;
  }

  .racelist .list .races .race-row.summary-columns {
    padding: 0px 0.75em;
  }

  .racelist .list .races .race-row {
    align-items: center;
    justify-content: center;
    font-size: 0.75em !important;
  }

  .racelist .list .races .race-row .race-titles {
    font-size: 1em;
  }
}
.relative {
  position: relative;
}
.text-center {
  text-align: center;
}
.custom-tooltip {
  top: -7px;
  left: 0px;
  right: -30px;
  box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
    0 4px 6px -4px rgba(255, 255, 255, 0.1);
  min-width: 90px;
  background: #000000;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 9999999;
  position: absolute;
  .innerdiv {
    z-index: 999999;
    position: relative;
  }

  .pricepool-tooltip {
    text-align: center;

    .inner-wrapper {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
      margin: 8px 0;

      span {
        display: inline-block;
        min-width: 80px;
        padding: 12px 8px;
        border-radius: 5px;
        color: #ffffff;
        font-size: 16px;
        text-align: center;

        @media (max-width: 1000px) {
          min-width: 60px;
          font-size: 14px;
          padding: 10px 6px;
        }
      }

      .rank-gold {
        background-color: #d4af37 !important;
        color: #000000;
        text-align: left;
      }
      .rank-silver {
        background-color: #c0c0c0 !important;
        color: #000000;
        text-align: left;
      }
      .rank-bronze {
        background-color: #cd7f32 !important;
        color: #000000;
        text-align: left;
      }

      .rank {
        background-color: #4a4e69;
        text-align: left;
      }
    }
  }

  &::after {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(46deg) translateX(-50%);
    background: #000000;
    left: 50%;
    bottom: -9px;
  }
  &.firsthover {
    top: 32px;
    left: 0px;
    right: -30px;
    box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
      0 4px 6px -4px rgba(255, 255, 255, 0.1);
    min-width: 90px;
    background: #000000;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 9999999;
    position: absolute;
    .innerdiv {
      z-index: 9999999;
      position: relative;
    }
    &::after {
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      transform: rotate(46deg) translateX(-50%);
      background: #000000;
      left: 42%;
      bottom: 27px;
    }
  }
  &.three-tooltip {
    top: 11px;
    left: 0px;
    right: -30px;
    box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
      0 4px 6px -4px rgba(255, 255, 255, 0.1);
    min-width: 90px;
    background: #000000;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 9999999;
    position: absolute;
    .innerdiv {
      z-index: 9999999;
      position: relative;
    }
    &::after {
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      transform: rotate(46deg) translateX(-50%);
      background: #000000;
      left: 50%;
      bottom: -9px;
    }
  }
}

.event-label-tooltip {
  top: -45px;
}

.prize-type-tooltip {
  top: 0px;
  left: -50px;
  right: 0;
  width: 200px;
}

.prize-pooltooltip {
  top: 53px;
  left: 0px;
  right: -30px;
  &::after {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(46deg) translateX(-50%);
    background: #000000;
    left: 50%;
    top: 0px;
  }
}

.info-tooltip-menu {
  top: 40px;
  left: -100px;
  right: 0;
  width: 200px;
}

.pricepool-tooltip-cover {
  top: 60px;
  left: -4.5rem;
  min-width: 300px !important;
  border: 2px solid #00b937e7;

  @media (max-width: 1400px) {
    left: -6rem;
  }
  @media (max-width: 1300px) {
    left: -7rem;
  }
  @media (max-width: 1250px) {
    left: -8rem;
  }
  @media (max-width: 1167px) {
    left: -9rem;
  }
  @media (max-width: 1125px) {
    left: -10rem;
  }
  @media (max-width: 1000px) {
    left: -7rem;
    top: 40px;
    min-width: 240px !important;
  }
  &::after {
    content: none !important;
  }
}

// .custom-tooltip-1 {
//   top: 0%;
//   left: 0;
//   box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
//     0 4px 6px -4px rgba(255, 255, 255, 0.1);
//   background: #000000;
//   color: #ffffff;
//   font-size: 14px;
//   padding: 10px 20px;
//   border-radius: 8px;
//   z-index: 9999999;
//   position: absolute;
//   .innerdiv {
//     z-index: 9999999;
//     position: relative;
//     &::after {
//       content: " ";
//       position: absolute;
//       width: 10px;
//       height: 10px;
//       transform: rotate(46deg) translateX(-50%);
//       background: #000000;
//       left: 50%;
//       bottom: -20px;
//     }
//   }
// }
// .custom-tooltip-2 {
//   top: 18%;
//   left: 15%;
//   box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
//     0 4px 6px -4px rgba(255, 255, 255, 0.1);
//   background: #000000;
//   color: #ffffff;
//   font-size: 14px;
//   padding: 10px 20px;
//   border-radius: 8px;
//   z-index: 9999999;
//   position: absolute;
//   .innerdiv {
//     z-index: 9999999;
//     position: relative;
//     &::after {
//       content: " ";
//       position: absolute;
//       width: 10px;
//       height: 10px;
//       transform: rotate(46deg) translateX(-50%);
//       background: #000000;
//       left: 50%;
//       bottom: -20px;
//     }
//   }
// }
// .custom-tooltip-3 {
//   top: 28%;
//   left: 15%;
//   box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
//     0 4px 6px -4px rgba(255, 255, 255, 0.1);
//   background: #000000;
//   color: #ffffff;
//   font-size: 14px;
//   padding: 10px 20px;
//   border-radius: 8px;
//   z-index: 9999999;
//   position: absolute;
//   .innerdiv {
//     z-index: 9999999;
//     position: relative;
//     &::after {
//       content: " ";
//       position: absolute;
//       width: 10px;
//       height: 10px;
//       transform: rotate(46deg) translateX(-50%);
//       background: #000000;
//       left: 50%;
//       bottom: -20px;
//     }
//   }
// }

.hover-box {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  left: 30px;
  z-index: 999;
  width: 200px;
}
.floating {
  background: cyan;
  width: 150px;
  height: 50px;
  color: black;
  pointer-events: none;
}
.race-row {
  position: relative;
}

@media screen and (max-width: 440px) {
  .racelist > .race-classes {
    .classes {
      margin: 0px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.text-end {
  @media (max-width: 1024px) {
    justify-content: center !important;
  }
}

@media screen and (max-width: 400px) {
  .racelist > .topbar > .next {
    .box {
      padding: 5px;
    }

    .box > .race-name {
      font-size: 0.5em;
    }
  }
}

.custom-tooltip {
  z-index: 5000;
}
