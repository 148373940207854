.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 50px;
  //background color with linear gradient from black to light grey going up
  background: linear-gradient(
    to bottom,
    rgb(107, 107, 107) -100%,
    rgb(0, 0, 0) 100%
  );
  z-index: 50;

  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(77, 77, 77);
  @media (max-width: 400px) {
    height: 32px !important;
  }
  .inner {
    position: relative;
    width: 100%;
    max-width: 1636px;
    padding: 0 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .link-group {
      font-family: Audiowide;
      color: white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 30px;
      @media (max-width: 1450px) {
        gap: 0;
      }

      .connect-btn {
        background: var(--main-green);
        margin: 0 15px;
        &:disabled {
          background: black;
          color: white;
        }
      }
      .nav-link {
        // margin: 0 15px;

        a {
          text-decoration: none;
          color: inherit;
        }
      }
      .wide {
        width: 100%;
      }
    }
    .end {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      .balance {
        margin-left: auto;
        align-self: center;
        color: white;
      }
    }
    .logo {
      cursor: pointer;
      margin-left: 15px;
      width: 140px;
    }
  }
}

.mobile-nav-icon {
  display: none;
}

@media screen and (max-width: 1450px) {
  .navbar {
    position: relative;
    height: 30px;
    margin-bottom: -5px;
  }
  .navbar > .inner {
    padding: 0;
    position: fixed;
    top: 0;
    flex-direction: column-reverse;
  }
  .navbar > .inner > .link-group.mobile-true {
    display: flex;
  }
  .navbar > .inner > .link-group.mobile-false {
    display: none;
  }

  .navbar > .inner > .link-group {
    width: 100vw !important;
    z-index: 50;
    background: linear-gradient(
      to bottom,
      rgb(107, 107, 107) -100%,
      rgb(0, 0, 0) 100%
    );
    width: 100%;
    position: absolute;
    top: -2px;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: relative;
    .nav-link {
      margin: 8px !important;
      height: 1.5vh !important;
      display: flex;
      padding: 15px;
      flex-direction: column;
      justify-content: center;
      font-size: 0.9em;
      align-items: center;
      width: 80% !important;
      background-color: rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
      }
    }

    .default-hidden {
      display: none;
    }
  }
  .end {
    margin-left: 10px;
    width: 100%;
    justify-content: flex-start !important;
    background: linear-gradient(
      to bottom,
      rgb(107, 107, 107) -100%,
      rgb(0, 0, 0) 100%
    );
    padding: 0.25em;
    align-items: center;
  }

  .mobile-nav-icon {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    z-index: 1000;

    img {
      width: 3vh;
      margin-right: 15px;
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 400px) {
  .connect-btn {
    font-size: 0.5em;
    padding: 5px;
  }

  .logo {
    max-width: 70px;
  }
}
