.homebg {
  background-image: url("../../assets/home-bg.png");
  background-color: rgb(0, 0, 0);
  // background-image: url(/static/media/home-bg.7b9f991….png);
  background-size: 100%;
  color: rgb(255, 255, 255);
  font-family: Audiowide;
  height: 100vh;
  position: relative;
  width: 100%;
  background-position: 50% center;
  background-repeat: no-repeat;

  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: top;
  // background-color: black;
  // width: 100%;
  // height: 100vh;
  // position: relative;
  // font-family: Audiowide;
  // color: white;
  @media (min-width: 2000px) {
    background-size: contain !important;
  }

  @media (max-width: 1450px) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-color: black;
    width: 100%;
    height: 100vh;
    position: relative;
    font-family: Audiowide;
    color: white;
  }

  @media (max-width: 991px) {
    height: 100%;
  }

  .welcome {
    position: absolute;
    bottom: -30px;
    left: 38%;
    font-size: 26px;
    // display: flex;
    align-items: center;
    transform: translate(-50%, -50%);

    @media (max-width: 1200px) {
      width: 100%;
      bottom: 40px;
      left: 50%;
      justify-content: center;
    }

    @media (max-width: 992px) {
      font-size: 18px;
    }

    @media (max-width: 768px) {
      top: 88% !important;
      font-size: 16px;
    }

    span {
      margin: 0 10px;
    }
    @media (max-width: 768px) {
      span {
        margin: 0 5px;
      }
    }

    .arrow {
      margin-left: 10px;
      height: 30px;
    }
  }

  .info-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 100px;
    left: 1250px;
    font-size: 20px;

    .top {
      margin-top: 5px;
      margin-bottom: 25px;
    }
  }
  .community {
    margin-top: 18px;
    margin-left: 10px;
  }
  .email-ref {
    margin-top: 20px;
    display: flex;
    display: flex;
    justify-content: center;
    div {
      position: relative;
      button {
        width: 140px;
        height: 40px;
        padding: 0.25em;
        align-items: center;
        background-color: #1976d2;
        font-size: 14px;
        padding: 5px;
        color: white;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        border: none;
        outline: none;
      }
    }
  }

  .greet-msg {
    @media (max-width: 320px) {
      margin-left: 10px;
    }
    .dark-green {
      @media (max-width: 320px) {
        margin-left: 0;
      }
    }
  }
  @media (min-width: 2000px) {
    .box-flex {
      top: 39% !important;
      left: 14% !important;
    }

    .main-box {
      height: 60%;
      display: flex;
      justify-content: end;
      align-items: center;

      .boxes-container {
        margin-top: 0px !important;
      }
    }
  }

  @media (max-width: 768px) {
    .main-box {
      position: absolute;
      bottom: 135px;

      .boxes-container {
        padding: 0px !important;
      }
    }
  }

  .boxes-container {
    float: right;
    margin-top: 143px;

    @media (min-width: 1201px) and (max-width: 1440px) {
      margin-top: 84px;
    }

    @media (min-width: 991px) and (max-width: 1200px) {
      margin-top: 130px;
    }

    @media (max-width: 991px) {
      margin-top: 0;

      padding: 59px 0 393px 0;
    }

    @media (max-width: 768px) {
      margin-top: 0 !important;
      // padding: 226px 0 204px 0 !important;
    }

    .box {
      text-decoration: none;
      width: 300px;
      height: 65px;
      font-size: 24px;
      background: #1f1f1f;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #2bb900;
      border-radius: 14px;
      margin-top: 20px;

      @media (max-width: 768px) {
        margin-top: 5px;
      }

      @media (max-width: 400px) {
        font-size: 18px;
      }
    }
  }

  .box-flex {
    text-decoration: none;
    position: absolute;
    width: fit-content;
    min-width: 400px;
    max-width: 400px;
    height: fit-content;
    min-height: 65px;
    font-size: 24px;
    background: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #2bb900;
    border-radius: 14px;

    @media (min-width: 1277px) and (max-width: 1440px) {
      min-width: 357px !important;
      max-width: 357px !important;
      min-height: 65px !important;
      font-size: 18px !important;
      top: 320px !important;
      left: 27px !important;
    }

    @media (min-width: 991px) and (max-width: 1276px) {
      top: 407px !important;
      left: 119px !important;
      min-width: 357px !important;
      max-width: 357px !important;
      min-height: 65px !important;
      font-size: 18px !important;
      transform: rotate(353deg) !important;
    }

    @media (max-width: 991px) {
      top: 387px !important;
      left: 125px !important;
      transform: rotate(355deg) !important;
      min-width: 357px !important;
      max-width: 357px !important;
      min-height: 65px !important;
      font-size: 18px !important;
    }

    @media (max-width: 768px) {
      top: 15px !important;
      left: 0 !important;
      transform: rotate(0deg) !important;
      min-width: auto;
    }

    @media (max-width: 475px) {
      font-size: 14px !important;
      min-height: 40px !important;
      margin: 0px 10px !important;
      max-width: 283px !important;
      min-width: 233px !important;
      position: absolute !important;
      left: 47% !important;
      transform: translateX(-50%) !important;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:visited {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: none;
    color: white;
  }

  a:focus {
    text-decoration: none;
    color: white;
  }

  a:active {
    text-decoration: none;
    color: white;
  }
}

@media screen and (max-width: 1000px) {
  .homebg {
    // background-size: 80%;
    background-repeat: no-repeat;
    // background-position: 40% -50px;
    background-color: black;
    width: 100%;
    position: relative;
    font-family: Audiowide;
    color: white;

    .flex-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      height: 100%;
      padding-top: 30vh;
    }

    .flex-section {
      // border:2px solid red !important;
      width: 100%;
    }

    .flex-section.upper-section {
      padding-top: 5%;
      height: 30vh;
    }

    .boxes-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .box {
      font-size: 100%;
      position: relative;
      top: 0px !important;
      left: 0px;
      margin: 15px auto;
    }
  }

  .welcome {
    position: relative;
    top: 80%;
    left: auto;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.75em;

    span {
    }

    img {
      width: 0.75em;
    }
  }
}

@media screen and (max-width: 991px) {
  .homebg {
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .homebg {
    background-position: 42% 50%;
    height: 100vh !important;
    position: relative;
  }
}

@media screen and (max-width: 400px) {
  .box {
    max-width: 200px !important;
  }
}
