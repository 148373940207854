.tokens {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  overflow: scroll;
  .no-tokens-text {
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.733);
    margin-top: 20px;
    @media (max-width: 575px) {
      font-size: 13px;
      margin-top: 20px;
    }
    a {
      margin: 0 5px;
      text-decoration: none;
      color: var(--main-green);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .subtitle {
    font-family: Audiowide;
    font-size: 30px;
    color: rgb(255, 255, 255);
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 5px;
    @media (max-width: 575px) {
      font-size: 17px;
      margin-top: 9px;
    }
  }
  .amounts {
    font-family: Audiowide;
    font-size: 20px;
    color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    span{
        color: var(--main-green);;
    }
    &.text-yellow{
      color: rgb(255, 251, 0);
    }
    @media (max-width: 575px) {
      font-size: 12px;
      margin-top: 9px;
    }
  }
}
.progress-data {
  display: flex;
  margin-bottom: 15px;
}
.row-popup {
  display: flex;
  margin: 10px 0;
  transition: ease all 0.3s;
  .dog {
    transition: ease 300ms all;
    width: 400px;
    height: 55px;
    position: relative;
    background: black;
    border: 2px solid var(--main-green);
    border-radius: 3px;
    margin-left: 20px;
    overflow: hidden;
    @media (max-width: 575px) {
      width: 275px;
    }
    @media (min-width: 1200px) and (max-width: 1480px) {
      width: 350px;
    }
    .img-container {
      overflow: hidden;
      max-height: 100%;
      width: 90px;
      .join-dog {
        height: 100%;
        transform-origin: -1% 0%;
        scale: 10%;
        object-fit: cover;
        margin-top: none;
      }
    }

    .divider {
      position: absolute;
      left: 0px;
      background: none;
      height: 55px;
      width: 56px;
    }
    .name-type {
      width: 55%;
      position: absolute;
      top: 5px;
      left: 94px;
      font-family: Arial;
      .name {
        font-weight: 600;
        padding-left: 15px;
        padding-bottom: 5px;
        color: white;
        border-bottom: 1px solid var(--main-green);
      }
      .type {
        font-weight: 600;
        padding-left: 15px;
        padding-top: 5px;
        color: grey;
      }
    }
    .join-button {
      position: absolute;
      top: 22%;
      left: 82%;
      font-weight: 600;
      font-size: 16px;

      color: white;
      background: var(--main-green);
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: green;
      }
      &:disabled {
        background: grey;
        cursor: not-allowed;
      }
    }
  }
}

.confirm-screen {
  font-family: Audiowide;
  font-size: 30px;
  color: rgb(255, 255, 255);
  padding: 50px 75px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  .back-button {
    cursor: pointer;
    margin-bottom: 15px;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  
  .dog {
    .token-image {
      img {
        max-width: 350px;
        transform-origin: -1% 0%;
        object-fit: cover;
      }
    }
  }

  .confirm-button {
  }
  .idle-modal{
display: flex;
flex-direction: column;
justify-content: space-between;
    height: 100%;

align-items: center;
  }
  .idle-modal{
    .confirm-button{
      
      font-size: 20px;
    }
  }
  .conf-logo{
    width: 80px;
    height: 90px;
  }
}
