.footer {
  background: black;
  padding-bottom: 50px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  @media (max-width:1000px) {
    padding-top: 70px;
  }
  .footer-data{
    display: flex;
    justify-content: space-between;
    @media (max-width:992px) {
      display: block;
    }
    .logo-copyright {
      align-self: flex-end;
      img {
        margin-bottom: 20px;
      }
      span{
        @media (max-width:992px) {
          display: none;
        }
      }
    }
    .copy-benifit{
      display: none;
      @media (max-width:992px) {
        display: block;
        text-align: center;
        margin-top: 40px;
      }
    }
    .group-1 {
      display: flex;
      flex-direction: column;
      // margin: 0 250px;
      .contact {
        display: flex;
        justify-content: center;
        color: white;
        font-family: Arial;
        font-weight: 600;
        font-size: 26px;
        text-decoration: none;
        margin: 20px 0;
        margin-left: 25px;
        @media (max-width:576px) {
          font-size: 14px;
          margin-left: 0;
          margin-top: 40px;
        }
      }
      .legals {
        display: flex;
        justify-content: center;
        color: white;
        font-family: Arial;
        font-weight: 400;
        font-size: 18px;
        text-decoration: none;
        margin-top: 15px;
        gap: 30px;
        @media (max-width:576px) {
          gap: 20px;
          font-size: 14px;
        }
        a,
        a:link,
        a:visited,
        a:focus,
        a:hover,
        a:active,
        span {
          color: white;
          // margin: 0 25px;
          text-decoration: none;
        }
      }
      .link-container {
        display: flex;
        flex-direction: row;
        @media (max-width:992px) {
          justify-content: space-between;
        }
        @media (max-width:640px) {
          display: block;
        }
        .about {
          display: flex;
          flex-direction: column;
          h2 {
            letter-spacing: 1px;
            color: var(--main-green);
            font-family: Audiowide;
            font-weight: 600;
            @media screen and (max-width:425px) {
             font-size: 18px;
            }
          }
          a {
            color: white;
            font-family: Arial;
            font-weight: 500;
            letter-spacing: 1px;
            font-size: 16px;
            text-decoration: none;
            margin: 7px 0;
            @media screen and (max-width:425px) {
              font-size: 11px;
              margin: 3px 0;
             }
          }
        }
  
        .social {
          margin-left: 150px;
          display: flex;
          flex-direction: column;
          @media (max-width:992px) {
            margin-left: 0;
          }
          h2 {
            letter-spacing: 1px;
            color: var(--main-green);
            font-family: Audiowide;
            font-weight: 600;
          }
          .content {
            display: flex;
            letter-spacing: 0.5px;
            .left {
            }
            .right {
              margin-left: 75px;
              @media (max-width:425px) {
                margin-left: 25px;
              }
            }
            .media-link {
              cursor: pointer;
              display: flex;
              align-items: center;
              color: white;
              font-family: Arial;
              font-weight: 500;
              font-size: 16px;
              text-decoration: none;
              margin: 7px 0;
              @media screen and (max-width:425px) {
                font-size: 11px;
                margin: 3px 0;
               }
              img {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width:1000px) {
  .footer{
    display:block;
    .logo-copyright > img {
      max-width: 240px;
      @media screen and (max-width:768px) {
        max-width: 180px;
      }
      @media screen and (max-width:425px) {
        max-width: 105px;
      }
    }
    .group-1{
      margin:0px;
      .contact{
        font-size:1em;
      }
    }
    .group-1 > .link-container{
      display:block;
      .social {
        margin-left:0px;
      }
      .social > .content > .right{
        margin-left:0px;
      }
    }
  }
}