.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width:1000px) { 
    margin-top: 20px;
  }
  @media (max-width:400px) { 
    margin-top: 0px !important;
  }
}
.content-padding {
  position: relative;
  padding-top: 50px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  @media (max-width:1450px) { 
    padding-top: 20px;
   
  }
  // @media (min-width:768px) and (max-width:1000px) { 
  //   min-height: 100vh !important;
  // }
}
.noscroll {
  overflow: hidden;
}


@media screen and (max-width: 1000px){
  .content-padding{
    position:relative;
    padding-top:0px;
    display:block;
  }
}