.kennel-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .top-tag {
    background: black;
    font-family: Arial;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center; /* align vertical */
    height: 30px;
    padding: 0.25em 250px;
    justify-content: center;
    border-bottom: 2px solid rgb(131, 131, 131);
    span {
      margin-right: auto;
      cursor: pointer;
    }
  }
  .kennel-header {
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    height: 100px;
    font-family: Audiowide;
    font-weight: 600;
    font-size: 32px;
    padding-left: 250px;
    //linear gradient background from top to bottom with 2 colors
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.651) -200%,
      rgba(0, 0, 0, 1) 100%
    );
    .picture {
      margin-right: 20px;
    }
    .clipboard {
      margin-left: 24px;
      width: 28px;
    }
    .settings {
      cursor: pointer;
      margin-left: auto;
      margin-right: 200px;
      display: flex;
      font-size: 20px;
      color: grey;
      transition: ease all 300ms;
      img {
        margin-right: 10px;
        width: 28px;
      }
      &:hover {
        filter: brightness(150%);
      }
    }
  }

  .kennel-content {
    .detail-header {
      margin-top: 30px;
      background: black;
      color: white;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Audiowide;
      font-weight: 600;
      font-size: 36px;
      padding-left: 150px;
      padding-right: 350px;
      @media (max-width: 991px) {
        padding: 0;
        justify-content: center;
      }
      @media (max-width: 768px) {
        font-size: 24px;
      }
      @media (max-width: 425px) {
        font-size: 18px;
      }
      .dog-class {
        font-family: Arial;
        font-size: 24px;
      }
    }
    .kennel-detail {
      display: flex;
      padding: 75px 0;
      .detail-content {
        display: flex;
        flex-direction: column;
        margin: 0 150px;
        margin-right: 50px;

        .dog-image {
          margin-bottom: 25px;
          width: min-content;
          border: 2px solid grey;
          overflow: hidden;
          box-sizing: border-box;
          img {
            vertical-align: bottom;
            object-fit: cover;
            height: 300px;
            width: 500px;
          }
        }
        .extra-icons {
          display: flex;

          margin-top: 50px;

          color: grey;
          font-size: 24px;
          width: 100%;

          .group {
            margin-left: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-left: 10px;
            }
          }
          .group:nth-child(2) {
            margin-left: 100px;
          }
        }
        @media (min-width: 768px) and (max-width: 1199px) {
          margin: 0 56px;
          .dog-image {
            img {
              vertical-align: bottom;
              object-fit: cover;
              height: 245px;
              width: 427px;
            }
          }
          .fatigue {
            width: 431px;
          }
          .extra-icons {
            .group:nth-child(2) {
              margin-left: 71px;
            }
          }
        }
        @media (max-width: 545px) {
          margin: 0 10px !important;
          .dog-image {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .fatigue {
            width: 100%;
          }
          .extra-icons {
            display: flex;
            justify-content: space-between;
          }
        }
        @media (max-width: 425px) {
          .dog-image {
            img {
              height: 180px !important;
            }
          }
          .extra-icons {
            .group {
              margin-left: 0px !important;
              margin-bottom: 10px !important;
            }
          }
        }
        @media (max-width: 375px) {
          .extra-icons {
            flex-direction: column;
          }
        }
      }
      .detailed-info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);

        .detail {
          display: flex;
          flex-direction: column;
          margin: 0 30px;
          margin-bottom: 50px;
          .title {
            font-family: Audiowide;
            font-size: 24px;
            color: grey;
            margin-bottom: 12px;
          }
          .value {
            display: flex;
            font-family: Arial;
            font-size: 24px;
            color: white;
            .highlight {
              display: flex;
              align-items: center;
              img {
                margin-left: 6px;
              }
            }
          }
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
          .detail {
            margin: 0 10px;
            .title {
              font-size: 16px;
            }
            .value {
              font-size: 16px;
            }
          }
        }
        @media (min-width: 768px) and (max-width: 1199px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(5, 1fr);
          .detail {
            margin: 0 10px;
            .title {
              font-size: 16px;
            }
            .value {
              font-size: 16px;
            }
          }
        }
        @media (min-width: 768px) and (max-width: 991px) {
          grid-template-columns: repeat(1, 1fr);
          .detail {
            margin: 0 10px 18px 10px;
          }
        }
        @media (max-width: 545px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(5, 1fr);
          .detail {
            .title {
              font-size: 13px !important;
              margin-bottom: 6px;
            }
            .value {
              font-size: 13px !important;
            }
          }
        }
        @media (max-width: 424px) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(9, 1fr);
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
        .detail-content {
          margin: 0 auto;
        }
        .detail {
          margin: 0 10px 18px 10px !important;
          .title {
            font-size: 16px !important;
          }
          .value {
            font-size: 16px !important;
          }
        }
      }
    }
  }
  .kennel-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: Arial;

    font-weight: 600;
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.651) -150%,
      rgba(0, 0, 0, 1) 100%
    );
    .logo-shadow {
      position: absolute;
      top: 80%;
      left: 90%;
    }
    .stat-header {
      display: grid;
      grid-template-columns: repeat(4, 300px);
      align-items: center;
      height: 50px;
      color: white;
      padding-left: 250px;
      font-size: 20px;
    }
    .stat-info {
      display: grid;
      grid-template-columns: repeat(4, 300px);
      align-items: center;
      padding-left: 250px;
      background: black;
      height: 50px;

      font-size: 20px;
    }

    .holder {
      position: relative;
      padding-right: 300px;
      @media (max-width: 1540px) {
        padding-right: 50px;
      }
      .kennel-info {
        margin: 30px 100px;
        margin-bottom: 0;
        margin-right: 0;
        color: grey;
        @media (max-width: 1540px) {
          margin: 30px 0 0 50px;
        }
        .kennel-info-header {
          font-size: 28px;
          margin-bottom: 8px;
        }
        .filters {
          display: flex;
          margin-bottom: 20px;
          justify-content: space-between;

          .container-box {
            background: black;
            border: 3px solid #009c2f;
            border-radius: 5px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            font-size: 24px;
          }
          .search-icon {
            height: 36px;
            width: 36px;
          }
          .filter-icon {
            height: 36px;
            width: 36px;
            margin-right: 10px;
          }
          .downarrow-icon {
            height: 36px;
            width: 36px;
            margin-left: 20px;
          }
          .icon {
            filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(211deg)
              brightness(91%) contrast(90%);
          }
          input {
            width: 350px;
            background: none;
            color: var(--main-green);
            height: 24px;
            font-family: Arial;
            font-size: 24px;
            padding: 2px;
            border: none;
            margin: 0;
            margin-left: 10px;
            &:focus {
              outline: none;
            }
          }
          .filter {
            margin-left: 25px;
            width: 200px;
            cursor: pointer;
          }

          .sort {
            margin-left: auto;
            align-items: center;
            display: flex;
            color: grey;
            .sort-by {
              font-size: 24px;
              margin-right: 12px;
            }
            div {
              width: 250px;
            }
          }
        }
        .dogs {
          display: flex;
          width: 100%;
          .kennel-table-main {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            @media (max-width: 767px) {
              flex-direction: column;
            }
          }
          .d-flex {
            display: flex;
            align-items: center;
            width: 43%;
            flex: 1;
            @media (max-width: 767px) {
              width: 100%;
              justify-content: space-around;
              margin-top: 6px;
            }
          }
          @media (min-width: 1120px) and (max-width: 1300px) {
            max-width: 1100px;
            margin: 0 auto;
          }

          @media (min-width: 769px) and (max-width: 1119px) {
            max-width: 768px;
            margin: 0 auto;
            .fatigue {
              width: 260px !important;
            }
            .dog-name {
              font-size: 18px !important;
            }
            .bloodline {
              font-size: 19px !important;
              margin-left: 23px !important;
            }
          }
          @media (min-width: 580px) and (max-width: 768px) {
            max-width: 768px;
            margin: 0 auto;
            .fatigue {
              width: 368px !important;
            }
            .dog-name {
              font-size: 18px !important;
            }
            .bloodline {
              font-size: 19px !important;
              margin-left: 23px !important;
            }
          }
          @media (max-width: 579px) {
            .img-container {
              flex: none;
              width: 90px;
            }
            .fatigue {
              max-width: 368px;
              width: 100% !important;
            }
            .qunt {
              margin: 0 14px !important;
              font-size: 16px !important;
              min-width: 43px !important;
            }
            .dog-name {
              font-size: 14px !important;
            }
            .bloodline {
              font-size: 14px !important;
              margin-left: 14px !important;
            }
          }
          @media (max-width: 375px) {
            .dog-name {
              font-size: 10px !important;
            }
            .bloodline {
              font-size: 11px !important;
              margin-left: 14px !important;
            }
            .qunt {
              margin: 0 8px !important;
              font-size: 12px !important;
              min-width: 30px !important;
            }
          }
          flex-direction: column;
          .row {
            cursor: pointer;
            background: black;
            border: 3px solid #009c2f;
            border-radius: 5px;
            height: 80px;
            display: flex;

            align-items: center;
            cursor: pointer;
            margin-bottom: 15px;
            position: relative;
            .img-container {
              overflow: hidden;
              img {
                vertical-align: bottom;
                height: 80px;
                object-fit: cover;
                transform-origin: 15% 5%;
                scale: 200%;
              }
            }

            .divider {
              position: absolute;
              left: 37px;
              background: none;
              height: 80px;
              width: 56px;
            }
            .dog-name {
              margin-left: 20px;
              color: white;
              font-size: 30px;
            }
            .bloodline {
              font-size: 24px;
              margin-left: 40px;
              color: grey;
            }
            .fatigue {
              margin-left: auto;
            }
            .qunt {
              margin: 0 20px;
              color: white;
              font-size: 30px;
              min-width: 76px;
            }
          }
        }
      }
    }
  }
}

.fatigue {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 504px;

  color: grey;
  padding: 6px 10px;
  border: 2px grey solid;
  box-sizing: border-box;
  @media (min-width: 768px) and (max-width: 1200px) {
  }
  .gradient-slider {
    position: relative;
    width: 55%;
    height: 80%;
    min-height: 20px;
    border: 1px solid rgb(0, 199, 83);
    background: linear-gradient(to right, black -20%, rgb(0, 199, 83));
    .indicator {
      position: absolute;
      top: -5px;
      left: 5px;
      height: 150%;
      min-height: 20px;
      border-left: 1px solid rgb(0, 199, 83);
    }
  }
}

@media screen and (max-width: 1300px) {
  .kennel-main {
    .kennel-header {
      padding-left: 0px;
      justify-content: center;
      font-size: 1.5em;
    }
    .kennel-header > .picture {
      position: relative;
      top: auto;
      left: auto;
      padding: 0px 20px;
    }

    .kennel-header > .settings {
      margin-right: 0px;
      margin-left: 0px;
      padding: 0px 20px;
    }

    .kennel-content > .stat-header {
      padding-left: 0px;
      display: flex;
      justify-content: space-evenly;
      span {
        font-size: 0.75em;
      }
    }

    .kennel-content > .holder {
      padding: 0px;
      margin: 0px;
      .kennel-info {
        font-size: 0.75em;
        margin: 0px;
      }
      .kennel-info > .kennel-info-header {
        font-size: 1.5em;
        margin: 1em 0px;
        text-align: center;
      }
    }

    .kennel-content > .holder > .kennel-info > .filters {
      justify-content: center;
      .container-box {
        img {
          width: 0.75em;
          height: auto;
        }
        input {
          width: 100%;
          font-size: 0.75em;
        }
      }

      .sort {
        margin-left: 10px;
        div {
          font-size: 2em;
        }
      }
    }
    .kennel-content > .logo-shadow {
      position: fixed;
      top: 85%;
      left: 90%;
      width: 40px;
    }

    .kennel-content > .stat-info {
      grid-template-columns: none;
      display: flex;
      padding-left: 0px;
      justify-content: space-evenly;
      padding: 5px 0px;
      font-size: 1em;
    }
  }
}
@media screen and (max-width: 1000px) {
  .settings {
    margin-left: 20px !important;
    gap: 12px;
  }
}
@media screen and (max-width: 425px) {
  .settings {
    margin-left: 10px !important;
    gap: 2px;
  }
}
@media screen and (max-width: 1000px) {
  .kennel-main {
    .top-tag {
      padding: 0.25em 0px;
      span {
        margin: auto;
      }
    }

    .kennel-header {
      padding: 0px;
      font-size: 1em;
      // justify-content: space-evenly;
    }
    .kennel-header > .picture {
      position: relative;
      top: auto;
      left: auto;
      height: 3em;
      width: 3em;
      padding: 0px;
    }
    .kennel-header > .settings {
      font-size: 1em;
      margin-left: 0;
      margin-right: 0;
      align-items: center;
      padding: 0px;
      img {
        margin: auto 5px;
        width: 2em;
      }
    }

    .kennel-content > .logo-shadow {
      position: fixed;
      top: 92%;
      left: 82%;
      width: 40px;
      padding: 0px;
    }

    .kennel-content > .stat-header {
      grid-template-columns: none;
      display: flex;
      padding-left: 0px;
      font-size: 1em;
      justify-content: space-evenly;
      span {
        font-size: 0.75em;
      }
    }

    .kennel-content > .stat-info {
      display: flex;
      padding: 0px;
      grid-template-columns: none;
      justify-content: space-evenly;
    }

    .kennel-content > .holder {
      padding-right: 0px;
    }

    .kennel-content > .holder > .kennel-info {
      margin: 0px;
      .kennel-info-header {
        font-size: 1em;
        text-align: center;
      }
      .filters {
        margin: 0px;
        margin-bottom: 30px;
        padding: 1em;
        display: flex;
        flex-wrap: wrap;
      }
      .filters > .container-box {
        font-size: 16px;
        width: 50%;
        height: auto;
        margin: 2px;
        padding: 0.25em 1em;
        input {
          font-size: 0.75em;
          width: 100%;
        }
        img {
          width: 1em;
          height: auto;
        }
      }
      .filters > .filter {
        width: auto;
        margin: 2px;
        width: 30%;
        // font-size: 0.75em;
        padding: 0.25em 0px;
      }
      .filters > .sort {
        margin-left: 0px;
        padding: 0.25em 0px;
        width: 100%;
        justify-content: center;
        .sort-by {
          font-size: 1em;
          // width: 30%;
        }
        div {
          font-size: 13px;
          width: 50%;
          height: auto;
          padding: 0.25em 0px;
          img {
            height: 2em;
            width: 1em;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .kennel-header {
    font-size: 0.75em !important;
  }
  .stat-header {
    font-size: 0.75em !important;
  }
}
